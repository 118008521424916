import React, { useContext, Suspense, useState } from 'react';

import QRCode from 'qrcode.react';
import { MainContext, shuffleArray, ImageIn, mediaLinks, gldraw, Calender, Agenda, Filters, PostIn } from './Context/mainContext';
import { PostiveIcons, DateIn, ShapeIn, LogoCreator } from './Helper/icons';
import './main.css'
import './header.css'
import { Header } from './Helper/Header';





const options = [
    "Calender",
    "Agenda"
  ];
  


const Editor = React.lazy(() => import('./Helper/Editor'));
const CurrentCard = React.lazy(() => import('./Helper/currentCard'));
const Cards = React.lazy(() => import('./Helper/cards'));
const BackIcon = React.lazy(() => import('./Helper/backIcon'));




const Main = () => {

    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    const { countyName, qrCodeRef, text, canvasEl, Download, currentState, wordIn, handleInput, handleCurrentMedia, extractedInfo, wordBeforeAgenda, result, FullView, handleFullView, handleCurrentState } = useContext(MainContext);
    const FilterID = ['sepia', 'blur', 'duotone', 'duotone-green'];
    const [FilterIDS, setFilterID] = React.useState(null);

    const shuffleFilters = () => {
        const shuffledFilters = shuffleArray(FilterID);
        setFilterID(shuffledFilters[0]);
    };



const CountyName = 'Howard';
const countyOpenDates = ["OPEN", "CLOSED"][0];


const TextIn = [ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]

    const Template = [
        {
            id: 0,
            itemName: "Howard County",
            bigText: "Engineer",
            meduimText: "Medium",
            smallText: "Office",
            BG: "pink",
            BackgroundIcon: <BackIcon />,
            checkIn: !countyName,
            icon: "",
            buttonAction: "",
            sentiments: <PostiveIcons />,
            link: "https://assets.codepen.io/9632/S6g8rNmQ_400x400.jpg",
            linkBackground: "",
            linkFilter: FilterIDS,
            Filter: "sepia",
            imageX: 10,
            imageY: 10,
            imageWidth: "100",
            imageHeight: "100",
            imageTranslate: "translate(450 100)",
            Tx:[Calender({CountyName, countyOpenDates}), Agenda({CountyName, countyOpenDates})][selectedIndex],
            TxIcon: ["M13.5412 0.494507L14.6922 8.99311L19.4165 1.83551L16.7661 9.99186L24.1282 5.59291L18.2013 11.7915L26.7429 11.0225L18.7135 14.0357L26.7429 17.0489L18.2013 16.2799L24.1282 22.4785L16.7661 18.0796L19.4165 26.2359L14.6922 19.0783L13.5412 27.5769L12.3903 19.0783L7.6659 26.2359L10.3163 18.0796L2.95427 22.4785L8.88114 16.2799L0.339506 17.0489L8.36893 14.0357L0.339506 11.0225L8.88114 11.7915L2.95427 5.59291L10.3163 9.99186L7.6659 1.83551L12.3903 8.99311L13.5412 0.494507Z"][0],
            Post: TextIn,
            Time: <DateIn />,
            logoLink: "",
            shapes:[ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]
        },
        {
            id: 1,
            itemName: "Howard County",
            bigText: "Engineer",
            meduimText: "Medium",
            smallText: "Office",
            BG: "pink",
            BackgroundIcon: <BackIcon />,
            checkIn: !countyName,
            icon: "",
            buttonAction: "",
            sentiments: <PostiveIcons />,
            link: "https://assets.codepen.io/9632/S6g8rNmQ_400x400.jpg",
            linkBackground: "", //ImageIn[0]
            linkFilter: FilterIDS,
            Filter: "sepia",
            imageX: 10,
            imageY: 10,
            imageWidth: "100",
            imageHeight: "100",
            imageTranslate: "translate(450 100)",
            Tx:[Calender({CountyName, countyOpenDates}), Agenda({CountyName, countyOpenDates})][selectedIndex],
            TxIcon: ["M13.5412 0.494507L14.6922 8.99311L19.4165 1.83551L16.7661 9.99186L24.1282 5.59291L18.2013 11.7915L26.7429 11.0225L18.7135 14.0357L26.7429 17.0489L18.2013 16.2799L24.1282 22.4785L16.7661 18.0796L19.4165 26.2359L14.6922 19.0783L13.5412 27.5769L12.3903 19.0783L7.6659 26.2359L10.3163 18.0796L2.95427 22.4785L8.88114 16.2799L0.339506 17.0489L8.36893 14.0357L0.339506 11.0225L8.88114 11.7915L2.95427 5.59291L10.3163 9.99186L7.6659 1.83551L12.3903 8.99311L13.5412 0.494507Z"][0],
            Post: TextIn,
            Time: <DateIn />,
            logoLink: "",
            shapes:[ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]
        },
        {
            id: 0,
            itemName: "Howard County",
            bigText: "Engineer",
            meduimText: "Medium",
            smallText: "Office",
            BG: "pink",
            BackgroundIcon: <BackIcon />,
            checkIn: !countyName,
            icon: "",
            buttonAction: "",
            sentiments: <PostiveIcons />,
            link: "https://assets.codepen.io/9632/S6g8rNmQ_400x400.jpg",
            linkBackground: "",
            linkFilter: FilterIDS,
            Filter: "sepia",
            imageX: 10,
            imageY: 10,
            imageWidth: "100",
            imageHeight: "100",
            imageTranslate: "translate(450 100)",
            Tx:[Calender({CountyName, countyOpenDates}), Agenda({CountyName, countyOpenDates})][selectedIndex],
            TxIcon: ["M13.5412 0.494507L14.6922 8.99311L19.4165 1.83551L16.7661 9.99186L24.1282 5.59291L18.2013 11.7915L26.7429 11.0225L18.7135 14.0357L26.7429 17.0489L18.2013 16.2799L24.1282 22.4785L16.7661 18.0796L19.4165 26.2359L14.6922 19.0783L13.5412 27.5769L12.3903 19.0783L7.6659 26.2359L10.3163 18.0796L2.95427 22.4785L8.88114 16.2799L0.339506 17.0489L8.36893 14.0357L0.339506 11.0225L8.88114 11.7915L2.95427 5.59291L10.3163 9.99186L7.6659 1.83551L12.3903 8.99311L13.5412 0.494507Z"][0],
            Post: TextIn,
            Time: <DateIn />,
            logoLink: "",
            shapes:[ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]
        },
        {
            id: 1,
            itemName: "Howard County",
            bigText: "Engineer",
            meduimText: "Medium",
            smallText: "Office",
            BG: "pink",
            BackgroundIcon: <BackIcon />,
            checkIn: !countyName,
            icon: "",
            buttonAction: "",
            sentiments: <PostiveIcons />,
            link: "https://assets.codepen.io/9632/S6g8rNmQ_400x400.jpg",
            linkBackground: "", //ImageIn[0]
            linkFilter: FilterIDS,
            Filter: "sepia",
            imageX: 10,
            imageY: 10,
            imageWidth: "100",
            imageHeight: "100",
            imageTranslate: "translate(450 100)",
            Tx:[Calender({CountyName, countyOpenDates}), Agenda({CountyName, countyOpenDates})][selectedIndex],
            TxIcon: ["M13.5412 0.494507L14.6922 8.99311L19.4165 1.83551L16.7661 9.99186L24.1282 5.59291L18.2013 11.7915L26.7429 11.0225L18.7135 14.0357L26.7429 17.0489L18.2013 16.2799L24.1282 22.4785L16.7661 18.0796L19.4165 26.2359L14.6922 19.0783L13.5412 27.5769L12.3903 19.0783L7.6659 26.2359L10.3163 18.0796L2.95427 22.4785L8.88114 16.2799L0.339506 17.0489L8.36893 14.0357L0.339506 11.0225L8.88114 11.7915L2.95427 5.59291L10.3163 9.99186L7.6659 1.83551L12.3903 8.99311L13.5412 0.494507Z"][0],
            Post: TextIn,
            Time: <DateIn />,
            logoLink: "",
            shapes:[ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]
        },
        {
            id: 0,
            itemName: "Howard County",
            bigText: "Engineer",
            meduimText: "Medium",
            smallText: "Office",
            BG: "pink",
            BackgroundIcon: <BackIcon />,
            checkIn: !countyName,
            icon: "",
            buttonAction: "",
            sentiments: <PostiveIcons />,
            link: "https://assets.codepen.io/9632/S6g8rNmQ_400x400.jpg",
            linkBackground: "",
            linkFilter: FilterIDS,
            Filter: "sepia",
            imageX: 10,
            imageY: 10,
            imageWidth: "100",
            imageHeight: "100",
            imageTranslate: "translate(450 100)",
            Tx:[Calender({CountyName, countyOpenDates}), Agenda({CountyName, countyOpenDates})][selectedIndex],
            TxIcon: ["M13.5412 0.494507L14.6922 8.99311L19.4165 1.83551L16.7661 9.99186L24.1282 5.59291L18.2013 11.7915L26.7429 11.0225L18.7135 14.0357L26.7429 17.0489L18.2013 16.2799L24.1282 22.4785L16.7661 18.0796L19.4165 26.2359L14.6922 19.0783L13.5412 27.5769L12.3903 19.0783L7.6659 26.2359L10.3163 18.0796L2.95427 22.4785L8.88114 16.2799L0.339506 17.0489L8.36893 14.0357L0.339506 11.0225L8.88114 11.7915L2.95427 5.59291L10.3163 9.99186L7.6659 1.83551L12.3903 8.99311L13.5412 0.494507Z"][0],
            Post: TextIn,
            Time: <DateIn />,
            logoLink: "",
            shapes:[ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]
        },
        {
            id: 1,
            itemName: "Howard County",
            bigText: "Engineer",
            meduimText: "Medium",
            smallText: "Office",
            BG: "pink",
            BackgroundIcon: <BackIcon />,
            checkIn: !countyName,
            icon: "",
            buttonAction: "",
            sentiments: <PostiveIcons />,
            link: "https://assets.codepen.io/9632/S6g8rNmQ_400x400.jpg",
            linkBackground: "", //ImageIn[0]
            linkFilter: FilterIDS,
            Filter: "sepia",
            imageX: 10,
            imageY: 10,
            imageWidth: "100",
            imageHeight: "100",
            imageTranslate: "translate(450 100)",
            Tx:[Calender({CountyName, countyOpenDates}), Agenda({CountyName, countyOpenDates})][selectedIndex],
            TxIcon: ["M13.5412 0.494507L14.6922 8.99311L19.4165 1.83551L16.7661 9.99186L24.1282 5.59291L18.2013 11.7915L26.7429 11.0225L18.7135 14.0357L26.7429 17.0489L18.2013 16.2799L24.1282 22.4785L16.7661 18.0796L19.4165 26.2359L14.6922 19.0783L13.5412 27.5769L12.3903 19.0783L7.6659 26.2359L10.3163 18.0796L2.95427 22.4785L8.88114 16.2799L0.339506 17.0489L8.36893 14.0357L0.339506 11.0225L8.88114 11.7915L2.95427 5.59291L10.3163 9.99186L7.6659 1.83551L12.3903 8.99311L13.5412 0.494507Z"][0],
            Post: TextIn,
            Time: <DateIn />,
            logoLink: "",
            shapes:[ShapeIn[Math.floor(Math.random() * ShapeIn.length)] ]
        }

        
    ]

    React.useEffect(() => {
        shuffleFilters();
    }, [FilterIDS])



    return (

<>
<Header options={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>
    
        <Suspense fallback={<div>Loading...</div>}>
            <div ref={qrCodeRef}>
                <QRCode value={text} renderAs="canvas" style={{ display: 'none' }} />
            </div>

            <React.Fragment>
                <canvas ref={canvasEl} style={{ display: "none" }} />
            </React.Fragment>

            <h1>{wordBeforeAgenda}</h1>
            <h2>{extractedInfo.urls?.[0]}</h2>
            <h2>{extractedInfo.urlsB?.[0]}</h2>
            <h1>{extractedInfo.times?.[0]}</h1>
            <h1>{extractedInfo.addresses?.[0]} {' '}</h1>
            <h1>{extractedInfo.phones?.[0]}</h1>
            <h1>{extractedInfo.years?.[0]}</h1>
            <h1>{extractedInfo.emails?.[0]}</h1>
            <h1>{extractedInfo.agendas?.[0]}</h1>
            <div style={{ display: !result ? "none" : " " }}>
                {extractedInfo.addresses?.[0]} {' '}
                {extractedInfo.iowaCities?.[0]}
            </div>
            <br />

            <div className="main_section">
                <div className="sub_section">
                
                    {FullView ? <CurrentCard currentState={currentState} Filters={Filters} /> : <article>
                        {shuffleArray([...Template]).map((data, index) => {
                            const handle = () => {
                                handleCurrentState(data)
                                handleFullView()
                            }
                            return <Cards handle={handle} data={data} key={index} gldraw={gldraw} currentState={currentState} Filters={Filters} />
                        })}
                    </article>}
                </div>
                <aside>
                    <nav className="nav_link">
                        {mediaLinks.map((data, index) => {
                            const handle = () => {
                                handleCurrentMedia(data)
                            }
                            return (
                                <a onClick={handle} key={index}
                                    className="link ">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" style={{ fill: data.color }}>
                                        {data.icon}
                                    </svg>
                                </a>
                            )
                        })}
                    </nav>
                    <Editor shuffleFilters={shuffleFilters} wordIn={wordIn} handleInput={handleInput} Download={Download} />
                </aside>
            </div>
        </Suspense>
        
</>

    )


};









export default Main;


























//📢 Notice and Call of Board Meeting 📢

//📆 Date: Tuesday, 23 May 2023
//⏰ Time: Study Session - 9:30 AM, Regular Session - 10:00 AM
//📍 Location: Meeting Room, Lower Level of Madison Co. Annex, 201 W Court, Winterset, Iowa 50273



const SVG = [
    <svg

        viewBox="0 0 500 500"
    >

        <path
            fill="#fdfefe"
            stroke="#000"
            strokeMiterlimit={10}
            d="M26 29h446.1v446.1H26z"
        />
        <path
            fill="#f05266"
            d="m258.2 118-14.5-4-11.6 9.6c-1.3 1.1-3.2.2-3.3-1.5l-.6-15-12.8-8c-1.4-.9-1.2-3 .4-3.6l14.1-5.2 3.7-14.5c.4-1.6 2.5-2.1 3.5-.8l9.4 11.7 15-1c1.7-.1 2.7 1.7 1.8 3.1l-8.3 12.5 5.6 13.9c.6 1.7-.8 3.3-2.4 2.8z"
        />
        <text transform="translate(83.851 193.933)">
            <tspan x={0} y={0} font-size="50px" fontFamily="ComicSansMS-Bold">
                Clay County is
            </tspan>
            <tspan x="65.9" y={60} font-size="50px" fontFamily="ArialRoundedMTBold">
                CLOSED
            </tspan>
        </text>
        <path fill="#262262" d="M30.1 397.1h439.1V467H30.1z" opacity=".54" />
        <text
            fill="#f1f2f2"
            font-size="25px"
            transform="translate(182.139 437.818)"
        >
            Date: 05/29/2023
        </text>
        <linearGradient
            id="SVGID_1_"
            x1="61.999"
            x2="160.113"
            y1="401.615"
            y2="401.615"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset={0} stopColor="#fff33b" />
            <stop offset=".059" stopColor="#ffe029" />
            <stop offset=".13" stopColor="#ffd217" />
            <stop offset=".203" stopColor="#fec90e" />
            <stop offset=".281" stopColor="#fdc70c" />
            <stop offset=".668" stopColor="#f3903f" />
            <stop offset=".888" stopColor="#ed683c" />
            <stop offset={1} stopColor="#e93e3a" />
        </linearGradient>
        <path
            fill="url(#SVGID_1_)"
            d="M152.3 384.7c-9-21-23.6-37-30.9-34-12.5 5.1 7.4 29.5-53.9 54.4-5.3 2.2-6.6 10.8-4.4 15.9 2.2 5.1 9.5 10.3 14.8 8.1.9-.4 4.3-1.5 4.3-1.5 3.8 5.1 7.7 2.1 9.1 5.3l6.6 15.2c1.2 2.9 4.1 5.5 6.1 4.7l11.7-4.4c2.7-1 3.3-3.4 2.5-5.3-.9-2-4.5-2.6-5.5-5s-4.4-9.9-5.4-12.3c-1.3-3.2 1.5-5.9 5.5-6.3 27.9-2.9 33.1 14.3 42.6 10.5 7.4-3 5.9-24.3-3.1-45.3zm-3.2 34.1c-1.6.7-12.6-8-19.6-24.3-7-16.3-6.1-31.3-4.5-31.9 1.6-.7 12.3 9.8 19.4 26.1 7 16.3 6.3 29.4 4.7 30.1z"
        />
        <text
            fill="#39b54a"
            font-size="25px"
            transform="translate(160.113 304.446)"
        >
            On Memorial Day
        </text>
    </svg>,




    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        version="1.1"
        viewBox="0 0 500 500"
    >
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n    .st3{fill:#ed1c24}.st4{fill:#9b8579}.st5{font-family:"ComicSansMS-Bold"}.st6{font-size:50px}\n  '
            }}
        />
        <path
            fill="#27aae1"
            stroke="#000"
            strokeMiterlimit={10}
            d="M26 29h446.1v446.1H26z"
            opacity=".33"
        />
        <path
            fill="#f05266"
            d="m423.2 445.3-29-8-23 19c-2.6 2.1-6.5.4-6.6-2.9l-1.3-29.6-25.5-15.8c-2.8-1.8-2.4-5.9.7-7.1l28.2-10.3L374 362c.8-3.2 5-4.1 7.1-1.5l18.7 23.2 30-2c3.3-.2 5.5 3.4 3.6 6.2l-16.6 24.6L428 440c1.2 3.1-1.6 6.2-4.8 5.3z"
        />
        <path fill="#fff" d="M52.2 39.4h395.5v201.4H52.2z" />
        <path
            d="M240.4 321.6c-19.9-46.4-52-81.7-68.2-75.1-27.5 11.2 16.4 65-118.9 119.9-11.7 4.8-14.6 23.8-9.8 35.1 4.9 11.3 20.9 22.7 32.5 17.9 2-.8 9.4-3.2 9.4-3.2 8.3 11.2 17.1 4.6 20.2 11.7l14.6 33.5c2.8 6.3 9 12.2 13.5 10.4l25.8-9.8c5.9-2.2 7.3-7.5 5.5-11.6-1.9-4.5-9.9-5.8-12.2-11-2.3-5.2-9.7-21.9-11.8-27.1-2.9-7.1 3.3-13 12.2-13.9 61.6-6.4 73.1 31.6 94 23.1 16.4-6.6 13.1-53.5-6.8-99.9zm-7 75.2c-3.6 1.4-27.8-17.6-43.3-53.7-15.5-36-13.5-68.9-9.9-70.4 3.6-1.4 27.2 21.6 42.7 57.6 15.5 36 14.1 65 10.5 66.5z"
            className="st3"
        />
        <text transform="translate(101.831 95.692)">
            <tspan x={0} y={0} className="st4 st5 st6">
                REMINDER:
            </tspan>
            <tspan x="-6.9" y={60} className="st4 st5 st6"></tspan>
            <tspan x="36.5" y={60} className="st3 st5 st6">
                DEADLINE
            </tspan>
            <tspan x="-36.6" y={120} className="st3 st5 st6">
                APPROACHING
            </tspan>
        </text>
        <text
            fontFamily="MyriadPro-Regular"
            fontSize={12}
            transform="translate(374.575 415.462)"
        >
            Logo
        </text>

    </svg>,

    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        version="1.1"
        viewBox="0 0 500 500"
    >
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n    .st2{fill:#9b8579}.st3{font-family:"ComicSansMS-Bold"}.st4{font-size:50px}.st8{fill:#808285}\n  '
            }}
        />
        <path fill="#27aae1" d="M0 0h500v500H0z" opacity=".49" />
        <path
            fill="#f05266"
            d="m281.5 129.9-29-8-23 19c-2.6 2.1-6.5.4-6.6-2.9l-1.3-29.6-25.5-15.8c-2.8-1.8-2.4-5.9.7-7.1L225 75.3l7.3-28.7c.8-3.2 5-4.1 7.1-1.5L258 68.3l30-2c3.3-.2 5.5 3.4 3.6 6.2L275 97.1l11.2 27.5c1.3 3.1-1.5 6.2-4.7 5.3z"
        />
        <text transform="translate(54.044 205.944)">
            <tspan x={0} y={0} className="st2 st3 st4">
                LAST DAY TO
            </tspan>
            <tspan x="-60.3" y={60} className="st2 st3 st4"></tspan>
            <tspan x="-16.9" y={60} fill="#ed1c24" className="st3 st4">
                VOTE ABSENTEE
            </tspan>
        </text>
        <text
            fontFamily="MyriadPro-Regular"
            fontSize={12}
            transform="translate(236.476 97.496)"
        >
            Logo
        </text>
        <path
            d="M347.6 312.9H179.4c-3.3 0-6 2.7-6 6v24h180.3v-24c0-3.3-2.7-6-6.1-6zM183.4 459.1c0 7.7 6.3 14 14 14h132.2c7.7 0 14-6.3 14-14V353H183.4v106.1zm50.1-86.1h60.1v20h-60.1v-20z"
            className="st8"
        />
        <text className="st3" fontSize={30} transform="translate(222.974 431.868)">
            VOTE
        </text>
        <path d="m229.452 299.5 14-14 24.042 24.042-14.001 14z" className="st8" />
    </svg>,

    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        version="1.1"
        viewBox="0 0 500 500"
    >
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n    .st2{fill:#9b8579}.st3{font-family:"ComicSansMS-Bold"}.st4{font-size:50px}\n  '
            }}
        />
        <path fill="#27aae1" d="M0 0h500v500H0z" opacity=".49" />
        <path
            fill="#f05266"
            d="m275.6 129.9-29-8-23 19c-2.6 2.1-6.5.4-6.6-2.9l-1.3-29.6-25.5-15.8c-2.8-1.8-2.4-5.9.7-7.1l28.2-10.3 7.3-28.7c.8-3.2 5-4.1 7.1-1.5l18.7 23.2 30-2c3.3-.2 5.5 3.4 3.6 6.2L269.2 97l11.2 27.5c1.3 3.2-1.6 6.3-4.8 5.4z"
        />
        <text transform="translate(25.521 198.8)">
            <tspan x={0} y={0} className="st2 st3 st4">
                LAST CHANCE TO
            </tspan>
            <tspan x="17.6" y={60} className="st2 st3 st4"></tspan>
            <tspan x="60.9" y={60} fill="#ed1c24" className="st3 st4">
                VOTE BY MAIL
            </tspan>
        </text>
        <text
            fontFamily="MyriadPro-Regular"
            fontSize={12}
            transform="translate(225.13 97.496)"
        >
            Logo
        </text>
        <path
            fill="#010101"
            d="m157.7 302.9 92.3 49.6c3.1 1.7 7.1 2.5 11.2 2.5 4 0 8.1-.8 11.2-2.5l92.3-49.6c6-3.2 11.7-15.8.7-15.8H157c-11-.1-5.4 12.5.7 15.8zM367.4 330l-95.1 49.6c-4.2 2.2-7.1 2.5-11.2 2.5-4 0-7-.3-11.2-2.5-4.2-2.2-87.8-46-94.7-49.6-4.8-2.5-4.8.4-4.8 2.7v89.8c0 5.2 7 12.3 12.3 12.3h197c5.3 0 12.3-7.1 12.3-12.3v-89.8c.2-2.3.3-5.2-4.6-2.7z"
        />
        <text
            fill="#e6e7e8"
            className="st3"
            fontSize={30}
            transform="translate(234.088 418.681)"
        >
            MAIL
        </text>
    </svg>,

    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        version="1.1"
        viewBox="0 0 500 500"
    >
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n    .st2{fill:#9b8579}.st3{font-family:"ComicSansMS-Bold"}.st4{font-size:50px}\n  '
            }}
        />
        <path fill="#27aae1" d="M0 0h500v500H0z" opacity=".49" />
        <path
            fill="#f05266"
            d="m284.8 118.4-29-8-23 19c-2.6 2.1-6.5.4-6.6-2.9l-1.3-29.6-25.5-15.8c-2.8-1.8-2.4-5.9.7-7.1l28.2-10.3 7.3-28.7c.8-3.2 5-4.1 7.1-1.5l18.7 23.2 30-2c3.3-.2 5.5 3.4 3.6 6.2l-16.6 24.6 11.2 27.5c1.2 3.1-1.6 6.3-4.8 5.4z"
        />
        <text transform="translate(99.984 198.8)">
            <tspan x={0} y={0} className="st2 st3 st4">
                Polls Close in
            </tspan>
            <tspan x="38.4" y={60} className="st2 st3 st4"></tspan>
            <tspan x="81.8" y={60} fill="#ed1c24" className="st3 st4">
                2 Hours
            </tspan>
        </text>
        <text
            fontFamily="MyriadPro-Regular"
            fontSize={12}
            transform="translate(239.482 84.36)"
        >
            Logo
        </text>
        <text
            fill="#e6e7e8"
            className="st3"
            fontSize={30}
            transform="translate(57.678 449.422)"
        >
            www.clyacountyelection.com
        </text>
        <path
            fill="#010101"
            d="M248.4 323.5c-2.7 1.6 7.2 25.4 9.6 29.2 2.1 3.4 6.6 4.4 9.9 2.3 3.4-2.1 4.4-6.5 2.3-9.9-2.4-3.8-19.2-23.2-21.8-21.6zm-2-26c11.5-4 23.9-4 35.4 0 2 .7 4.6-.7 3.4-3.7-.8-2.2-1.7-4.3-2-5.1-.8-2-3.6-3.7-4.8-4-4.6-1-9.4-1.6-14.3-1.6s-9.7.6-14.3 1.6c-1.2.3-4 1.9-4.8 4l-2 5.1c-1.2 3 1.4 4.4 3.4 3.7zm72.1 4.7c-2.3-2.8-4.9-5.4-7.7-7.7-.9-.8-3.6-1.4-5.3.3l-9.9 9.8c2.5 1.7 4.8 3.7 6.9 5.8 2.2 2.2 4 4.5 5.8 6.9l9.8-9.8c1.8-1.7 1.2-4.4.4-5.3zm-54.4-1.7c-26.8 0-48.4 21.7-48.4 48.4 0 26.8 21.7 48.4 48.4 48.4 26.8 0 48.4-21.7 48.4-48.4s-21.7-48.4-48.4-48.4zm0 84.9c-20.1 0-36.5-16.3-36.5-36.5s16.3-36.5 36.5-36.5c20.1 0 36.5 16.3 36.5 36.5-.1 20.2-16.4 36.5-36.5 36.5z"
        />
    </svg>,
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        version="1.1"
        viewBox="0 0 500 500"
    >
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n    .st2{fill:#9b8579}.st3{font-family:"ComicSansMS-Bold"}.st4{font-size:40px}\n  '
            }}
        />
        <path fill="#27aae1" d="M0 0h500v500H0z" opacity=".49" />
        <path
            fill="#f05266"
            d="m284.8 118.4-29-8-23 19c-2.6 2.1-6.5.4-6.6-2.9l-1.3-29.6-25.5-15.8c-2.8-1.8-2.4-5.9.7-7.1l28.2-10.3 7.3-28.7c.8-3.2 5-4.1 7.1-1.5l18.7 23.2 30-2c3.3-.2 5.5 3.4 3.6 6.2l-16.6 24.6 11.2 27.5c1.2 3.1-1.6 6.3-4.8 5.4z"
        />
        <text transform="translate(142.803 164.953)">
            <tspan x={0} y={0} className="st2 st3 st4">
                BALLOTS{" "}
            </tspan>
            <tspan x="21.2" y={48} className="st2 st3 st4">
                OUT IN
            </tspan>
            <tspan x="-20.3" y={96} className="st2 st3 st4"></tspan>
            <tspan x="14.4" y={96} fill="#ed1c24" className="st3 st4">
                THE MAIL
            </tspan>
        </text>
        <text
            fontFamily="MyriadPro-Regular"
            fontSize={12}
            transform="translate(239.482 84.36)"
        >
            Logo
        </text>
        <text
            fill="#e6e7e8"
            className="st3"
            fontSize={30}
            transform="translate(57.678 449.422)"
        >
            www.clyacountyelection.com
        </text>
        <path
            fill="#010101"
            d="M259.4 272.1c-37.9 0-68.7 30.7-68.7 68.7s30.7 68.7 68.7 68.7 68.7-30.7 68.7-68.7-30.8-68.7-68.7-68.7zm-27 47.2h53.8c2.9 0 1.4 3.7-.2 4.6-1.6.9-23 13.9-23.8 14.4-.8.5-1.8.7-2.9.7-1 0-2-.2-2.9-.7l-23.8-14.4c-1.6-1-3-4.6-.2-4.6zm55.6 39.3c0 1.5-1.8 3.6-3.2 3.6h-50.9c-1.4 0-3.2-2.1-3.2-3.6v-26.1c0-.7 0-1.5 1.2-.8l24.4 14.4c.9.5 1.9.8 2.9.7 1 0 1.8-.1 2.9-.7l24.6-14.4c1.2-.7 1.2.1 1.2.8v26.1z"
        />
    </svg>,

    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        version="1.1"
        viewBox="0 0 500 500"
    >
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n    .st2{fill:#9b8579}.st3{font-family:"ComicSansMS-Bold"}.st4{font-size:40px}\n  '
            }}
        />
        <path fill="#27aae1" d="M0 0h500v500H0z" opacity=".49" />
        <path
            fill="#f05266"
            d="m141.6 464.5-29-8-23 19c-2.6 2.1-6.5.4-6.6-2.9L81.7 443l-25.5-15.8c-2.8-1.8-2.4-5.9.7-7.1l28.2-10.3 7.3-28.7c.8-3.2 5-4.1 7.1-1.5l18.7 23.2 30-2c3.3-.2 5.5 3.4 3.6 6.2l-16.6 24.6 11.2 27.5c1.3 3.2-1.6 6.3-4.8 5.4z"
        />
        <text transform="translate(113.276 91.876)">
            <tspan x={0} y={0} className="st2 st3 st4">
                KNOW YOUR
            </tspan>
            <tspan x="-25.2" y={48} className="st2 st3 st4"></tspan>
            <tspan x="9.5" y={48} fill="#ed1c24" className="st3 st4">
                CANDIDATES
            </tspan>
        </text>
        <text
            fontFamily="MyriadPro-Regular"
            fontSize={12}
            transform="translate(96.547 431.722)"
        >
            Logo
        </text>
        <text
            fill="#e6e7e8"
            className="st3"
            fontSize={20}
            transform="translate(181.327 435.94)"
        >
            www.clyacountyelection.com
        </text>
        <path
            fill="#010101"
            d="M262.1 211.4c-37.9 0-68.7 30.7-68.7 68.7s30.7 68.7 68.7 68.7 68.7-30.7 68.7-68.7-30.8-68.7-68.7-68.7zm-27 47.2h53.8c2.9 0 1.4 3.7-.2 4.6-1.6.9-23 13.9-23.8 14.4-.8.5-1.8.7-2.9.7-1 0-2-.2-2.9-.7l-23.8-14.4c-1.6-.9-3-4.6-.2-4.6zm55.6 39.4c0 1.5-1.8 3.6-3.2 3.6h-50.9c-1.4 0-3.2-2.1-3.2-3.6v-26.1c0-.7 0-1.5 1.2-.8l24.4 14.4c.9.5 1.9.8 2.9.7 1 0 1.8-.1 2.9-.7l24.6-14.4c1.2-.7 1.2.1 1.2.8V298zM85.5 152.3h363.7v3.6H85.5zM71.2 43.2h363.7v3.6H71.2z"
        />
    </svg>

]

