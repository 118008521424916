import React, { useContext } from 'react';
import './landing.css'
import { NavLink } from 'react-router-dom';
import { MainContext } from '../Context/mainContext';


const Landing = () => {
  const { handleUrl } = useContext(MainContext)
  return (
    <>






      <header>
        <nav>
          <ul className="navList">
            <a to="/" >
              <button className="buttonLine" >
                <div className="line" />
                <div className="lineB" />
                <div className="lineC" />
              </button>

            </a>
            <div className="spacer" />


            <li >
              <NavLink to="/login" >

                <button className={'b-2'} >
                  Get Started
                </button>

              </NavLink>
            </li>




          </ul>

        </nav>
      </header>




      <>
        {/* Hero */}
        <section id="hero">
          <div className="land_container">
            <div className="land_row">
              <div className="col-sm-6" style={{ marginTop: "170px" }}>
                <h1 className="jumbo">
                  Social Media Toolkit for Counties
                </h1>
                <p>
                  Access dozens of high-resolution, eye-catching images that are ready for sharing on Facebook, Twitter, Website, Youtube, Email and on Instagram pages.
                </p>
                <a
                  href=""
                  className="btn btn-primary mr-sm"
                >
                  <i className="fa-solid fa-download" target="_blank" /> &nbsp;Get
                  Started
                </a>{" "}
                <a
                  href="#land_pricing"
                  className="btn btn-secondary"

                >
                  <i className="fa-brands fa-github" /> &nbsp;Learn More
                </a>
              </div>
              <div className="col-sm-6">


                <svg width="500" height="500" viewBox="0 0 500 500" style={{
                  fill: "url(#avatar)",
                  strokeWidth: "35px",



                }}>
                  <circle cx={250} cy={250} r={200} style={{
                    stroke: "rgba(250, 162, 0, 0.5)"
                  }} />
                </svg>
                <svg>
                  <defs>
                    <pattern
                      id="avatar"
                      width={1}
                      height={1}
                      viewBox="0 0 100 100"
                      preserveAspectRatio="none"
                    >
                      <image
                        xlinkHref="https://images.unsplash.com/photo-1616042616236-0e009a951cb6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=386&q=80"
                        width={100}
                        height={100}
                        preserveAspectRatio="none"
                      />
                    </pattern>
                  </defs>
                </svg>




              </div>
            </div>
          </div>
        </section>
        <main>
          {/* Pricing card_land s */}
          <section id="land_pricing" >
            <div className="land_container">
              <div className="land_row">
                <div className="col-sm-12 text-center">
                  <p className="kicker">Trusted Worldwide</p>
                  <h2>Designed for business teams like yours</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </div>
              <div className="land_row mt-lg">
                <div className="col-sm-4">
                  <div className="card_land  text-center">
                    <h3>Starter</h3>
                    <p className="mb-sm">
                      Best option for personal use &amp; for your next project.
                    </p>
                    <div className="card-price">
                      <span className="price">£29</span>
                      <span className="term">/month</span>
                    </div>
                    <ul role="list">
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Individual configuration</span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>No setup, or hidden fees</span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Team size:{" "}
                          <span className="font-semibold color-black">
                            1 developer
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Premium support:{" "}
                          <span className="font-semibold color-black">6 months</span>
                        </span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Free updates:{" "}
                          <span className="font-semibold color-black">6 months</span>
                        </span>
                      </li>
                    </ul>
                    <NavLink to="/checkout" className="btn btn-primary full-width" onClick={handleUrl}>
                      Get StartedFF
                    </NavLink>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card_land  text-center">
                    <h3>Company</h3>
                    <p className="mb-sm">
                      Relevant for multiple users, extended &amp; premium support.
                    </p>
                    <div className="card-price">
                      <span className="price">£99</span>
                      <span className="term">/month</span>
                    </div>
                    <ul role="list">
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Individual configuration</span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>No setup, or hidden fees</span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Team size:{" "}
                          <span className="font-semibold color-black">
                            10 developer
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Premium support:{" "}
                          <span className="font-semibold color-black">24 months</span>
                        </span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Free updates:{" "}
                          <span className="font-semibold color-black">24 months</span>
                        </span>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-primary full-width">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card_land  text-center">
                    <h3>Enterprise</h3>
                    <p className="mb-sm">
                      Best for large scale uses and extended redistribution rights.
                    </p>
                    <div className="card-price">
                      <span className="price">£299</span>
                      <span className="term">/month</span>
                    </div>
                    <ul role="list">
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Individual configuration</span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>No setup, or hidden fees</span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Team size:{" "}
                          <span className="font-semibold color-black">
                            100+ developers
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Premium support:{" "}
                          <span className="font-semibold color-black">36 months</span>
                        </span>
                      </li>
                      <li>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Free updates:{" "}
                          <span className="font-semibold color-black">36 months</span>
                        </span>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-primary full-width color-black">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="land_cta">
            <div className="land_container">
              <div className="land_row">
                <div className="col-sm-12 text-center">
                  <h2>Start your free trial today</h2>
                  <p>Try our Platform for 30 days. No credit card required.</p>
                  <a href="#" className="btn btn-primary">
                    Free trial for 30 days
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </>


  )
}


export { Landing }



















