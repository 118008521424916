import React, { useState, useEffect, Suspense, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Main, MainContext } from './Components';
import { Landing } from './Components/Landing/Landing';
import Checkout from './Components/Context/Checkout/checkOut';
import Cancel from './Components/Context/Checkout/cancle';
import Success from './Components/Context/Checkout/success';
import { Login } from './Components/Login';
import { Header } from './Components/Helper/Header';



function App() {
  const { url } = useContext(MainContext)


  
  return (
    <Suspense fallback={<p>Loading...</p>} >
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path={`/${url}`} element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
      </Routes>

    </Suspense>
  );
}

export { App }