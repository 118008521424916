import Print from "./print";

const Success = () => {
  const Letters = ["E", "DE", "F"]
      //CURRENT DATES
      const currentDateOb = new Date();
      const Data = currentDateOb.getDate();
      const Map = currentDateOb.getMonth() + 1;
      const Yap = String(currentDateOb.getFullYear() + 1);
      const K = `${Letters[0]}${Map}-${Data}${Letters[1]}-${Letters[2]}${Yap.substring(Yap.length - 2)}`;
    return (
      <div className="recipt">
        <h1>Success</h1>
        <h2>Thank you for your purchase!</h2>
        <p>Your Key License is:{K}</p>
        <Print/>



        <div className="container">
  <div className="receipt_box">
    <div className="head">
      <div className="logo">
        <svg height={42} width={42} viewBox="0 0 64 64">
          <path
            className="path1"
            fill="rgb(0, 157, 223)"
            d="M58.125 19.288c-2.987 13.262-12.212 20.262-26.75 20.262h-4.837l-3.363 21.35h-4.050l-0.212 1.375c-0.137 0.913 0.563 1.725 1.475 1.725h10.35c1.225 0 2.263-0.888 2.462-2.1l0.1-0.525 1.95-12.362 0.125-0.675c0.188-1.212 1.237-2.1 2.462-2.1h1.538c10.025 0 17.875-4.075 20.175-15.85 0.862-4.475 0.538-8.275-1.425-11.1z"
          />
          <path
            className="path2"
            fill="rgb(0, 46, 135)"
            d="M51.938 4.825c-2.962-3.375-8.325-4.825-15.175-4.825h-19.887c-1.4 0-2.6 1.012-2.813 2.4l-8.287 52.525c-0.162 1.038 0.638 1.975 1.688 1.975h12.287l3.087-19.563-0.1 0.612c0.212-1.388 1.4-2.4 2.8-2.4h5.837c11.462 0 20.438-4.65 23.063-18.125 0.075-0.4 0.15-0.788 0.2-1.163 0.775-4.975 0-8.375-2.7-11.438z"
          />
        </svg>
      </div>
      <div className="number">
        <div className="date">2021/03/25</div>
        <div className="ref">of-113</div>
      </div>
    </div>
    <div className="body">
      <div className="info">
        <div className="welcome">
          Hi, <span className="username">Kai Cheng</span>
        </div>
        <p>You've purchased (3) items in our store</p>
      </div>
      <div className="cart">
        <div className="title">cart</div>
        <div className="content">
          <ul className="cart_list">
            <li className="cart_item">
              <span className="index">1</span>
              <span className="name">T-Shirt Locaste</span>
              <span className="price">$48.00</span>
            </li>
            <li className="cart_item">
              <span className="index">2</span>
              <span className="name">Sikers Nike</span>
              <span className="price">$125.00</span>
            </li>
            <li className="cart_item">
              <span className="index">3</span>
              <span className="name">All Stars</span>
              <span className="price">$69.00</span>
            </li>
          </ul>
          <div className="total">
            <span>total</span>
            <span className="total_price">$242.00</span>
          </div>
        </div>
      </div>
    </div>
    <div className="foot">
      <img
        src="https://i.ibb.co/c8CQvBq/barcode.png"
        alt="barcode"
        className="barcode"
      />
    </div>
  </div>
</div>

      </div>
    );
  };
  
  export default Success;
  