import React, {useState, useEffect} from 'react';






const Header = ({selectedOption, setSelectedOption, options, setSelectedIndex})=>{

 
    const handleChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(selectedValue);
      setSelectedIndex(options.findIndex((option) => option === selectedValue));
    };
    return(
        <header>
            <nav>
                <ul className="navList">
                    <a href="/" >
                        <button className="buttonLine" >
                            <div className="line" />
                            <div className="lineB" />
                            <div className="lineC" />
                        </button>

                    </a>
                    <div className="spacer" />


                    <li >
                        <a >
                     
                  
        <select value={selectedOption} onChange={handleChange}>
  
          {options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
       
 

            
                   

                        </a>
                    </li>


         
                    <li>
                        <a>
                        <button className="b-2">
                                Download
                            </button>
                        </a>
                    </li>

                </ul>

            </nav>
        </header>


    )
}

export { Header}