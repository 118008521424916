import React, {useContext} from 'react'
import { MainContext } from '../Context/mainContext';



const DateIn = () => {
  const { extractedInfo } = useContext(MainContext)
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return !extractedInfo.years?.[0] ? `${day}-${month}-${year}` : extractedInfo.years?.[0]
}


const ShapeIn = [<circle
  cx={250}
  cy={250}
  r={200}
  stroke="rgba(250, 162, 0, 0.5)"
  strokeDasharray={0}
  transform-origin="center"
/>,
  <polygon
  className="Hexagon"
  points="175,50 425,50 500,250 425,450 175,450 100,250"
  style={{ stroke: "rgba(250, 162, 0, 0.5)" }}
/>,
  <path
  className="Heart"
  d="M380,150c-35-50-120-90-120-90s-85,40-120,90c-50,50-50,120-50,120s70,40,170,120c100-80,170-120,170-120s0-70-50-120z"
  style={{ stroke: "rgba(250, 162, 0, 0.5)" }}
/>,
  <polygon
  className="Star"
  points="250,50 315,200 450,220 355,330 400,470 250,390 100,470 145,330 50,220 185,200"
  style={{ stroke: "rgba(250, 162, 0, 0.5)" }}
/>,
<path class="shield" d="M404.48,187.94c-.93-.82-2.31-.91-3.34-.23-11.59,7.66-26.63,12.29-43.07,12.29-22.94,0-43.16-9.02-55.08-22.72-1.06-1.22-2.9-1.3-4.06-.18-12.16,11.78-30.87,19.34-51.86,19.34-16.39,0-31.38-4.6-42.95-12.22-.78-.51-1.76-.61-2.62-.23h0c-9.19,4.32-15.1,13.92-15.08,24.49v79.1c.82,57.97,35.78,109.33,87.77,128.95l11.15,4.23c4.69,1.85,9.65,2.84,14.66,2.93,5.14-.02,10.24-.97,15.08-2.82l11.05-4.23c51.91-19.71,86.75-71.12,87.46-129.06v-79.1c.02-8.05-3.41-15.52-9.1-20.54Z" style={{ stroke: "rgba(250, 162, 0, 0.5)" }} />
  ,

]



const LogoCreator = ()=>{
  return(
    <svg width="500px" height="500px" viewBox="0 0 500 500">
    <defs>
      <path
        id="circlePath"
        d="M250 350C194.8 350 150 305.2 150 250 150 194.8 194.8 150 250 150 305.2 150 350 194.8 350 250 350 305.2 305.2 350 250 350Z"
      />
    </defs>
    <text
      fontSize={40}
      fontWeight={600}
      fill="pink"
      letterSpacing={5}
      textLength={550}
      style={{ textShadow: "0 2px 15px rgba(0,0,0,.3)" }}
    >
      <textPath xlinkHref="#circlePath" textAnchor="middle" startOffset="50%">
        Engineer Department
      </textPath>
    </text>
    <defs>
      <path
        id="circleBottom"
        d="M100 251C100 334 167 401 250 401 333 401 400 334 400 251"
      />
    </defs>
    <text
      fontFamily="Montserrat, sans-serif"
      fontSize={18}
      fontWeight={400}
      fill="RED"
      letterSpacing={4}
      textLength={145}
      style={{ textShadow: "0 2px 15px rgba(0,0,0,.3)" }}
    >
      <textPath xlinkHref="#circleBottom" textAnchor="middle" startOffset="50%">
        SINCE 1973
      </textPath>
    </text>
    {/* Rings & Bottle:	 */}
    <g stroke="white" fill="none">
      <path
        strokeWidth="2.25"
        d="M322 371L322 371 322 371C364 347 391 302 391 251 391 173 328 110 251 110 173 110 110 173 110 251 110 302 138 347 179 371"
      />
      <path
        strokeWidth="3.5"
        d="M327 379L327 379 327 379C371 353 401 305 401 251 401 168 333 101 251 101 168 101 101 168 101 251 101 305 130 353 174 379"
      />
    </g>
    <path
      fill="RED"
      d="M227.6 167.3C224.5 168.7 223.4 171.9 223.2 176.4L223.1 182.6C224.6 182 234.2 180.1 250 180.1 265.8 180.1 275.4 182 276.9 182.6L276.8 176.4C276.6 171.9 275.9 168.2 272.4 167.3 265.8 165.7 259.5 165 250 165 241.2 165 231.7 165.7 227.6 167.3ZM225.2 186.4C222.4 187.1 220.4 188.5 220.4 191.9 220.4 195.4 222.4 197.5 225.2 197.7 225.2 208.9 219.4 216.8 213.2 227.6 207 238.3 200 250.2 200 275.7 200 309.8 201.2 313.7 204.8 321.1 208.3 328.4 213.9 333 250 333 286.1 333 291.7 328.4 295.2 321.1 298.8 313.7 300 309.8 300 275.7 300 250.2 293 238.3 286.8 227.6 280.6 216.8 274.8 208.9 274.8 197.7 277.6 197.5 279.6 195.4 279.6 191.9 279.6 188.5 277.6 187.1 274.8 186.4 272 185.7 264.8 184.5 250 184.5 235.2 184.5 228 185.7 225.2 186.4Z"
    />

  </svg>
  )
}



const PostiveIcons  = () => {
  const { postiveState, PositiveSet } = useContext(MainContext);

  console.log("eceeds", PositiveSet["Remarkable"])
     return postiveState.map((icon, index) => <g key={index} transform='translate(30 100) scale(15)' opacity={0.2}>
      {PositiveSet[icon]}
      </g>
    )
}



const SquareIcon = ({iconSize})=><g transform={iconSize}><rect width="50" height="30" x="10" y="10" fill="#FF6159" rx="8" /></g> 
const CircleIcon = ()=> <g transform='translate(500 50) scale(1.2)'><circle cx="30" cy="70" r="20" fill="#FFA830" /></g>
const PolygonIcon = ()=> <g transform='translate(500 50) scale(1.2)'><polygon points="77.5 32 93 63 62 63" fill="none" stroke="#6176EE" stroke-width="4" /></g>

const ButtonAction = () => <g transform='translate(25 400)'>
  <rect width="40%" height="50" x="10" y="10" fill="#FF6159" rx="8" />
  <text fill="white" fontSize="30" x="22%" y="44" textAnchor="middle" fontWeight={700}> Mark your calendars!</text>
</g>

export {SquareIcon,CircleIcon,PolygonIcon,ButtonAction,PostiveIcons, DateIn, ShapeIn, LogoCreator  }