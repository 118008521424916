const Colors = [
    {
      id: 1,
      primary: "#000000",
      secondary: "rgba(225, 225, 225)",
      primbuttn: "#4685ff",
      secbuttn: "#f2f2f2",
      accent: "#ffb084",
      governmentAssociations: [
        "Transparency",
        "Accountability",
        "Public Service",
        "Citizenship",
        "Community",
        "Public Trust",
        "Democratic Values",
        "Civic Engagement",
        "Policy-making",
        "Government Programs"
      ]
    },
    {
      id: 2,
      primary: "#f1faee",
      secondary: "#1d3557",
      primbuttn: "#a8dadc",
      secbuttn: "#06446a",
      accent: "#ffc7cc",
      governmentAssociations: [
        "Policy",
        "Legislation",
        "Governance",
        "Public Safety",
        "Collaboration",
        "Public Administration",
        "Regulation",
        "Law Enforcement",
        "Public Resources",
        "Emergency Preparedness"
      ]
    },
    {
      id: 3,
      primary: "#fffbff",
      secondary: "#252cbb",
      primbuttn: "#ca3f66",
      secbuttn: "#2e35c2",
      accent: "#ffccda",
      governmentAssociations: [
        "Sustainability",
        "Infrastructure",
        "Development",
        "Efficiency",
        "Resilience",
        "Urban Planning",
        "Transportation",
        "Environmental Protection",
        "Public Works",
        "Housing"
      ]
    },
    {
      id: 4,
      primary: "#ffc3a0",
      secondary: "#330c2f",
      primbuttn: "#eef9bf",
      secbuttn: "#573b58",
      accent: "#ffae71",
      governmentAssociations: [
        "Public Engagement",
        "Community Development",
        "Equity",
        "Inclusivity",
        "Accessibility",
        "Social Services",
        "Citizen Participation",
        "Volunteerism",
        "Community Outreach",
        "Diversity"
      ]
    },
    // Color Combination Set 2
    {
      id: 5,
      primary: "#ffcad4",
      secondary: "#800080",
      primbuttn: "#ffdf00",
      secbuttn: "#9932cc",
      accent: "#ff96ad",
      governmentAssociations: [
        "Public Health",
        "Emergency Management",
        "Public Awareness",
        "Education",
        "Service Excellence",
        "Healthcare",
        "Preventive Care",
        "Safety Measures",
        "Health Promotion",
        "Well-being"
      ]
    },
    // Color Combination Set 3
    {
      id: 6,
      primary: "#f9d8d6",
      secondary: "#006d77",
      primbuttn: "#ee6c4d",
      secbuttn: "#083d77",
      accent: "#ffb997",
      governmentAssociations: [
        "Innovation",
        "Technology",
        "Digital Transformation",
        "Data Governance",
        "Open Data",
        "Smart Cities",
        "Digital Services",
        "Cybersecurity",
        "E-Government",
        "Information Sharing"
      ]
    }
  ];

  export { Colors }