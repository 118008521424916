import React, { useState } from 'react'
import { useMavic } from './Context/mainContext';
import './header.css'
import Main from './main';



const Login = () => {
    const [Logs, setLogs] = useState(false)
    const [selectedCounty, setSelectedCounty] = useMavic("countyDb", '');
    const [selectedState, setSelectedState] = useMavic("stateDb", '');
    const [password, setPassword] = useMavic("psd", '');
    const Letters = ["E", "DE", "F"]
    const [formattedDate, setFormattedDate] = useMavic("Formated", '');

    const convertToDate = () => {
        const month = password.substring(1, 2);
        const date = password.substring(3, 5);
        const year = password.substring(7);
        const formatted = `0${month}/0${date}/20${year}`;
        setFormattedDate(formatted.replace(/-/g, ''));
    };


    const dateString = formattedDate.replace(/[DF]/g, "");
    const removeDate = new Date(Date.parse(dateString));
    const currentDate = new Date();
    const shouldRenderPost = currentDate < removeDate;


    //CURRENT DATES
    const currentDateOb = new Date();
    const Data = currentDateOb.getDate();
    const Map = currentDateOb.getMonth() + 1;
    const Yap = String(currentDateOb.getFullYear() + 1);
    const K = `${Letters[0]}${Map}-${Data}${Letters[1]}-${Letters[2]}${Yap.substring(Yap.length - 2)}`;

    const DB = () => {
        //Make sure E exists and the First letter
        let pattern = /^E/;
        let match = password.match(pattern);
        return match && shouldRenderPost? `matched ${match[0]}` : ""
    }



    const handleCountyChange = (event) => setSelectedCounty(event.target.value);
    const handleStateChange = (event) => setSelectedState(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);


    const handleLog = () => {
        convertToDate()
        setLogs(!Logs)
    }


    const counties = [countiesIA][0]
    return (
        <div>

            <section className={DB() ? `sideBar` : `sideBar open`} >
                {DB() ?
                    <section className="sect">
                        <Main />
                    </section> :
                    <div style={{marginTop: "100px"}}>
                        <div className="form-container">
                            <div>
                                <input
                                    type="text"
                                    className="input_form"
                                    id="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder="Enter Password"
                                    name="password" required
                                    autoComplete="current-password"

                                />
                                <button type="submit" className="button_form" onClick={handleLog}>Subscribe</button>
                         
                            </div>

                           
                        </div>














                    </div>
                }
            </section>
        </div>
    )
}


export { Login }





///<p>Selected county: {selectedCounty}</p>
//<p>Selected state: {selectedState}</p>
///<p>Password: {password}</p>

//<hr />

//<p> {dateString}</p>

//<h1>key:{K}</h1>
//<h1>Results: {DB()}</h1>












const countiesIA = [
    "Adair County",
    "Adams County",
    "Allamakee County",
    "Appanoose County",
    "Audubon County",
    "Benton County",
    "Black Hawk County",
    "Boone County",
    "Bremer County",
    "Buchanan County",
    "Buena Vista County",
    "Butler County",
    "Calhoun County",
    "Carroll County",
    "Cass County",
    "Cedar County",
    "Cerro Gordo County",
    "Cherokee County",
    "Chickasaw County",
    "Clarke County",
    "Clay County",
    "Clayton County",
    "Clinton County",
    "Crawford County",
    "Dallas County",
    "Davis County",
    "Decatur County",
    "Delaware County",
    "Des Moines County",
    "Dickinson County",
    "Dubuque County",
    "Emmet County",
    "Fayette County",
    "Floyd County",
    "Franklin County",
    "Fremont County",
    "Greene County",
    "Grundy County",
    "Guthrie County",
    "Hamilton County",
    "Hancock County",
    "Hardin County",
    "Harrison County",
    "Henry County",
    "Howard County",
    "Humboldt County",
    "Ida County",
    "Iowa County",
    "Jackson County",
    "Jasper County",
    "Jefferson County",
    "Johnson County",
    "Jones County",
    "Keokuk County",
    "Kossuth County",
    "Lee County",
    "Linn County",
    "Louisa County",
    "Lucas County",
    "Lyon County",
    "Madison County",
    "Mahaska County",
    "Marion County",
    "Marshall County",
    "Mills County",
    "Mitchell County",
    "Monona County",
    "Monroe County",
    "Montgomery County",
    "Muscatine County",
    "O'Brien County",
    "Osceola County",
    "Page County",
    "Palo Alto County",
    "Plymouth County",
    "Pocahontas County",
    "Polk County",
    "Pottawattamie County",
    "Poweshiek County",
    "Ringgold County",
    "Sac County",
    "Scott County",
    "Shelby County",
    "Sioux County",
    "Story County",
    "Tama County",
    "Taylor County",
    "Union County",
    "Van Buren County",
    "Wapello County",
    "Warren County",
    "Washington County",
    "Wayne County",
    "Webster County",
    "Winnebago County",
    "Winneshiek County",
    "Woodbury County",
    "Worth County",
    "Wright County"
];


const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];






