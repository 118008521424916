import React, {useEffect, useState} from 'react'
import {PostIn } from './db'
import { Colors } from './colors'




const StarIcons = []






const PostiveIcon = [<path d="M17.223 7.03c-1.584-3.686-4.132-6.49-5.421-5.967-2.189.891 1.304 5.164-9.447 9.533-.929.379-1.164 1.888-.775 2.792.388.902 1.658 1.801 2.587 1.424.161-.066.751-.256.751-.256.663.891 1.357.363 1.604.928l1.158 2.66c.219.502.715.967 1.075.83l2.05-.779c.468-.178.579-.596.436-.924-.154-.355-.786-.459-.967-.873-.18-.412-.769-1.738-.938-2.156-.23-.568.259-1.031.97-1.104 4.894-.512 5.809 2.512 7.475 1.834 1.287-.525 1.025-4.259-.558-7.942zm-.551 5.976c-.287.115-2.213-1.402-3.443-4.267-1.231-2.863-1.076-5.48-.79-5.597.286-.115 2.165 1.717 3.395 4.58 1.231 2.863 1.124 5.167.838 5.284z"/>,<path fill="white" d="M3.135 6.89c.933-.725 1.707-.225 2.74.971.116.135.272-.023.361-.1.088-.078 1.451-1.305 1.518-1.361.066-.059.146-.169.041-.292a36.238 36.238 0 0 1-.743-.951c-1.808-2.365 4.946-3.969 3.909-3.994-.528-.014-2.646-.039-2.963-.004-1.283.135-2.894 1.334-3.705 1.893-1.061.726-1.457 1.152-1.522 1.211-.3.262-.048.867-.592 1.344-.575.503-.934.122-1.267.414-.165.146-.627.492-.759.607-.133.117-.157.314-.021.471 0 0 1.264 1.396 1.37 1.52.105.122.391.228.567.071.177-.156.632-.553.708-.623.078-.066-.05-.861.358-1.177zm5.708.517c-.12-.139-.269-.143-.397-.029L7.012 8.63a.289.289 0 0 0-.027.4l8.294 9.439c.194.223.53.246.751.053l.97-.813a.54.54 0 0 0 .052-.758L8.843 7.407zM19.902 3.39c-.074-.494-.33-.391-.463-.182-.133.211-.721 1.102-.963 1.506-.24.4-.832 1.191-1.934.41-1.148-.811-.749-1.377-.549-1.758.201-.383.818-1.457.907-1.59.089-.135-.015-.527-.371-.363-.357.164-2.523 1.025-2.823 2.26-.307 1.256.257 2.379-.85 3.494l-1.343 1.4 1.349 1.566 1.654-1.57c.394-.396 1.236-.781 1.998-.607 1.633.369 2.524-.244 3.061-1.258.482-.906.402-2.814.327-3.308zM2.739 17.053a.538.538 0 0 0 0 .758l.951.93c.208.209.538.121.746-.088l4.907-4.824-1.503-1.714-5.101 4.938z" />, 
<path fill="yellow" d="M18.672 11H17v6c0 .445-.194 1-1 1h-4v-6H8v6H4c-.806 0-1-.555-1-1v-6H1.328c-.598 0-.47-.324-.06-.748L9.292 2.22c.195-.202.451-.302.708-.312.257.01.513.109.708.312l8.023 8.031c.411.425.539.749-.059.749z" />]
const ImageIn = ['https://images.unsplash.com/photo-1666919643134-d97687c1826c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=871&q=80','https://images.unsplash.com/photo-1685215902365-8283bb3bed69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80']
const svgLinks = [<path fill="pink" d="M3.135 6.89c.933-.725 1.707-.225 2.74.971.116.135.272-.023.361-.1.088-.078 1.451-1.305 1.518-1.361.066-.059.146-.169.041-.292a36.238 36.238 0 0 1-.743-.951c-1.808-2.365 4.946-3.969 3.909-3.994-.528-.014-2.646-.039-2.963-.004-1.283.135-2.894 1.334-3.705 1.893-1.061.726-1.457 1.152-1.522 1.211-.3.262-.048.867-.592 1.344-.575.503-.934.122-1.267.414-.165.146-.627.492-.759.607-.133.117-.157.314-.021.471 0 0 1.264 1.396 1.37 1.52.105.122.391.228.567.071.177-.156.632-.553.708-.623.078-.066-.05-.861.358-1.177zm5.708.517c-.12-.139-.269-.143-.397-.029L7.012 8.63a.289.289 0 0 0-.027.4l8.294 9.439c.194.223.53.246.751.053l.97-.813a.54.54 0 0 0 .052-.758L8.843 7.407zM19.902 3.39c-.074-.494-.33-.391-.463-.182-.133.211-.721 1.102-.963 1.506-.24.4-.832 1.191-1.934.41-1.148-.811-.749-1.377-.549-1.758.201-.383.818-1.457.907-1.59.089-.135-.015-.527-.371-.363-.357.164-2.523 1.025-2.823 2.26-.307 1.256.257 2.379-.85 3.494l-1.343 1.4 1.349 1.566 1.654-1.57c.394-.396 1.236-.781 1.998-.607 1.633.369 2.524-.244 3.061-1.258.482-.906.402-2.814.327-3.308zM2.739 17.053a.538.538 0 0 0 0 .758l.951.93c.208.209.538.121.746-.088l4.907-4.824-1.503-1.714-5.101 4.938z" />, <path fill="red" d="M18.672 11H17v6c0 .445-.194 1-1 1h-4v-6H8v6H4c-.806 0-1-.555-1-1v-6H1.328c-.598 0-.47-.324-.06-.748L9.292 2.22c.195-.202.451-.302.708-.312.257.01.513.109.708.312l8.023 8.031c.411.425.539.749-.059.749z" />]




const mediaLinks = [
  {
    id: 0,
    item: "faacebook",
    color: "#1093f4",
    icon: <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zm2.274 6.634h-1.443c-.171 0-.361.225-.361.524V8.6h1.805l-.273 1.486H10.47v4.461H8.767v-4.461H7.222V8.6h1.545v-.874c0-1.254.87-2.273 2.064-2.273h1.443v1.581z" />
  },
  {
    id: 1,
    item: "Instagram",
    color: "#E1306C",
    icon:  <path d="M13.498 6.651a1.656 1.656 0 0 0-.95-.949 2.766 2.766 0 0 0-.928-.172c-.527-.024-.685-.03-2.02-.03s-1.493.006-2.02.03a2.766 2.766 0 0 0-.929.172 1.656 1.656 0 0 0-.949.95 2.766 2.766 0 0 0-.172.928c-.024.527-.03.685-.03 2.02s.006 1.493.03 2.02a2.766 2.766 0 0 0 .172.929 1.656 1.656 0 0 0 .95.949 2.766 2.766 0 0 0 .928.172c.527.024.685.029 2.02.029s1.493-.005 2.02-.03a2.766 2.766 0 0 0 .929-.171 1.656 1.656 0 0 0 .949-.95 2.766 2.766 0 0 0 .172-.928c.024-.527.029-.685.029-2.02s-.005-1.493-.03-2.02a2.766 2.766 0 0 0-.171-.929zM9.6 12.168A2.568 2.568 0 1 1 12.168 9.6 2.568 2.568 0 0 1 9.6 12.168zm2.669-4.637a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zM11.267 9.6A1.667 1.667 0 1 1 9.6 7.933 1.667 1.667 0 0 1 11.267 9.6zM9.6 0a9.6 9.6 0 1 0 9.6 9.6A9.6 9.6 0 0 0 9.6 0zm4.97 11.661a3.67 3.67 0 0 1-.233 1.214 2.556 2.556 0 0 1-1.462 1.462 3.67 3.67 0 0 1-1.213.233c-.534.024-.704.03-2.062.03s-1.528-.006-2.062-.03a3.67 3.67 0 0 1-1.213-.233 2.556 2.556 0 0 1-1.462-1.462 3.67 3.67 0 0 1-.233-1.213c-.024-.534-.03-.704-.03-2.062s.006-1.528.03-2.062a3.67 3.67 0 0 1 .232-1.213 2.556 2.556 0 0 1 1.463-1.463 3.67 3.67 0 0 1 1.213-.232c.534-.024.704-.03 2.062-.03s1.528.006 2.062.03a3.67 3.67 0 0 1 1.213.232 2.556 2.556 0 0 1 1.462 1.463 3.67 3.67 0 0 1 .233 1.213c.024.534.03.704.03 2.062s-.006 1.528-.03 2.062z"/>
  },
  {
      id: 2,
      item: "Twitter",
      color: "#1093f4",
      icon: <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zm3.905 7.864c.004.082.005.164.005.244 0 2.5-1.901 5.381-5.379 5.381a5.335 5.335 0 0 1-2.898-.85c.147.018.298.025.451.025.886 0 1.701-.301 2.348-.809a1.895 1.895 0 0 1-1.766-1.312 1.9 1.9 0 0 0 .853-.033 1.892 1.892 0 0 1-1.517-1.854v-.023c.255.141.547.227.857.237a1.89 1.89 0 0 1-.585-2.526 5.376 5.376 0 0 0 3.897 1.977 1.891 1.891 0 0 1 3.222-1.725 3.797 3.797 0 0 0 1.2-.459 1.9 1.9 0 0 1-.831 1.047 3.799 3.799 0 0 0 1.086-.299 3.834 3.834 0 0 1-.943.979z"/>
    },
    {
     id: 3,
     item: "Youtube",
     color: "red",
     icon:<path d="M11.603 9.833L9.357 8.785C9.161 8.694 9 8.796 9 9.013v1.974c0 .217.161.319.357.228l2.245-1.048c.197-.092.197-.242.001-.334zM10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zm0 13.5c-4.914 0-5-.443-5-3.9s.086-3.9 5-3.9 5 .443 5 3.9-.086 3.9-5 3.9z"/>
    },
    {
      id: 4,
      item: "LinkedIn",
      color: "#0e76a8",
      icon:  <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM7.65 13.979H5.706V7.723H7.65v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.814v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z"/>
    },
    {
        id: 5,
        item: "Email",
        color: "gray",
        icon: <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM6.231 7h7.52c.399 0 .193.512-.024.643-.217.13-3.22 1.947-3.333 2.014s-.257.1-.403.1a.793.793 0 0 1-.402-.1L6.255 7.643C6.038 7.512 5.833 7 6.231 7zM14 12.5c0 .21-.252.5-.444.5H6.444C6.252 13 6 12.71 6 12.5V8.853c0-.092-.002-.211.172-.11l3.417 2.015a.69.69 0 0 0 .402.1c.146 0 .252-.011.403-.1l3.434-2.014c.174-.102.172.018.172.11V12.5z"/>
    }


];







const randomPositive = Math.floor(Math.random() *PostiveIcon.length);
const posConnect = PostiveIcon[randomPositive];

const PositiveSet = {
    "exciting": posConnect,
    "thrilled": posConnect,
    "proud": posConnect,
    "successful": posConnect,
    "accomplished": posConnect,
    "delighted": posConnect,
    "celebrating": posConnect,
    "remarkable": posConnect,
    "positive": posConnect,
    "beneficial": posConnect
};





const randomIndex = Math.floor(Math.random() *svgLinks.length);
const engConnect = svgLinks[randomIndex];

const images = {
    "engineer": engConnect,
    "county": engConnect,
    "road closure": engConnect,
    "detour": engConnect,
    "detour route": engConnect,
    "lane closure": engConnect,
    "construction": engConnect,
    "improvement project": engConnect,
    "pavement rehabilitation": engConnect,
    "bridge repair": engConnect,
    "traffic signal upgrade": engConnect,
    "culvert replacement": engConnect,
    "drainage improvement": engConnect,
    "right-of-way acquisition": engConnect,
    "environmental study": engConnect,
    "public meeting": engConnect,
    "public input": engConnect,
    "comment period": engConnect,
    "project schedule": engConnect,
    "project budget": engConnect,
    "safety improvement": engConnect,
    "intersection improvement": engConnect,
    "pedestrian crossing": engConnect,
    "sidewalk construction": engConnect,
    "road resurfacing": engConnect,
    "traffic flow": engConnect,
    "alternate route": engConnect,
    "heavy equipment": engConnect,
    "project status update": engConnect,
    "project completion": engConnect,
    "ribbon-cutting ceremony": engConnect,
    "public notice": engConnect,
    "press release": engConnect,
    "newsletter": engConnect,
    "notice": engConnect,
    "release": engConnect,
    "news": engConnect,
    "letter": engConnect,
    "project": engConnect,
    "traffic": engConnect,
    "bridge": engConnect,
    "intersection": engConnect,
    "maintenance": engConnect,
    "update": engConnect,
    "closure": engConnect,
    "safety": engConnect,
    "work": engConnect,
    "schedule": engConnect,
    "access": engConnect,
    "sidewalk": engConnect,
    "drainage": engConnect,
    "stormwater": engConnect,
    "culvert": engConnect,
    "permit": engConnect,
    "inspection": engConnect,
    "report": engConnect,
    "environmental": engConnect,
    "hazard": engConnect,
    "noise": engConnect,
    "meeting": engConnect,
    "survey": engConnect,
    "assessment": engConnect,
    "planning": engConnect,
    "community": engConnect,
    "feedback": engConnect,
    "residents": engConnect,
    "property": engConnect,
    "land": engConnect,
    "utilities": engConnect,
    "water": engConnect,
    "sewer": engConnect,
    "wastewater": engConnect,
    "electrical": engConnect,
    "gas": engConnect,
    "telecom": engConnect,
    "fiber-optic": engConnect,
    "right-of-way": engConnect,
    "easement": engConnect,
    "plans": engConnect,
    "specifications": engConnect,
    "bid": engConnect,
    "contractor": engConnect,
    "vendor": engConnect,
    "procurement": engConnect,
    "contract": engConnect,
    "budget": engConnect,
    "funding": engConnect,
    "grant": engConnect,
    "public works": engConnect,
    "infrastructure": engConnect,
    "design": engConnect,
    "geotechnical": engConnect,
    "surveying": engConnect,
    "traffic signal": engConnect,
    "signage": engConnect,
    "pavement": engConnect,
    "resurfacing": engConnect,
    "rehabilitation": engConnect,
    "streetlight": engConnect,
    "public transportation": engConnect,
    "bus": engConnect,
    "route": engConnect,
    "parking": engConnect,
    "bicycle": engConnect,
    "pedestrian": engConnect,
    "crosswalk": engConnect,
    "accessible": engConnect,
    "ADA compliance": engConnect,
    "emergency": engConnect,
    "snow removal": engConnect,
    "winter maintenance": engConnect,
    "landscaping": engConnect,
    "beautification": engConnect,
    "public park": engConnect,
    "recreation": engConnect,
    "trail": engConnect,
    "greenway": engConnect,
    "sustainability": engConnect,
    "energy-efficient": engConnect,
    "conservation": engConnect,
    "education": engConnect,
    "public service": engConnect,
    "website": engConnect,
    "contact": engConnect,
    "information": engConnect,
    "zoning": engConnect,
    "permitting": engConnect,
    "compliance": engConnect,
    "development": engConnect,
    "erosion control": engConnect,
    "surveyor": engConnect,
    "boundary": engConnect,
    "topographic": engConnect,
    "wetland": engConnect,
    "stream": engConnect,
    "floodplain": engConnect,
    "emergency management": engConnect,
    "evacuation": engConnect,
    "hazard mitigation": engConnect,
    "outreach": engConnect,
    "stakeholder": engConnect,
    "open house": engConnect,
    "information session": engConnect,
    "FAQ": engConnect,
    "announcement": engConnect,
    "notification": engConnect,
    "agenda": engConnect,
    "minutes": engConnect,
    "public comment": engConnect,
    "petition": engConnect,
    "petitioner": engConnect,
    "concern": engConnect,
    "complaint": engConnect,
    "request": engConnect,
    "application": engConnect,
    "review": engConnect,
    "approval": engConnect,
    "disapproval": engConnect,
    "resolution": engConnect,
    "ordinance": engConnect,
    "policy": engConnect,
    "regulation": engConnect,
    "code": engConnect,
    "permittee": engConnect,
    "violation": engConnect,
    "enforcement": engConnect,
    "abatement": engConnect,
    "penalties": engConnect,
    "appeal": engConnect,
    "court": engConnect,
    "legal": engConnect,
    "lawsuit": engConnect,
    "settlement": engConnect,
    "agreement": engConnect,
    "collaboration": engConnect,
    "partnership": engConnect,
    "grant program": engConnect,
    "funding opportunity": engConnect,
    "economic development": engConnect,
    "job creation": engConnect,
    "public-private partnership": engConnect,
    "stakeholder meeting": engConnect,
    "annual report": engConnect,
    "budgetary allocation": engConnect,
    "capital improvement plan": engConnect,
    "feasibility study": engConnect,
    "traffic study": engConnect,
    "pedestrian safety": engConnect,
    "bicycle infrastructure": engConnect,
    "public transportation plan": engConnect,
    "transit system": engConnect,
    "rail": engConnect,
    "airport": engConnect,
    "traffic calming": engConnect,
    "speed limit": engConnect,
    "road signage": engConnect,
    "traffic congestion": engConnect,
    "roundabout": engConnect,
    "roadway widening": engConnect,
    "shoulder": engConnect,
    "cul-de-sac": engConnect,
    "pavement markings": engConnect,
    "access road": engConnect,
    "right-turn lane": engConnect,
    "street resurfacing": engConnect,
    "traffic signal timing": engConnect,
    "transit hub": engConnect,
    "bus stop": engConnect,
    "multi-modal transportation": engConnect,
    "traffic calming measures": engConnect,
    "bicycle lane": engConnect,
    "public transit schedule": engConnect,
    "street sweeping": engConnect,
    "snow plowing": engConnect,
    "road closure announcement": engConnect,
    "construction timeline": engConnect,
    "environmental impact assessment": engConnect,
    "noise mitigation measures": engConnect,
    "water main replacement": engConnect,
    "sewer line repair": engConnect,
    "utility relocation": engConnect,
    "land acquisition": engConnect,
    "drainage improvement project": engConnect,
    "retention pond": engConnect,
    "green infrastructure": engConnect,
    "pedestrian safety campaign": engConnect,
    "school zone safety": engConnect,
    "speed enforcement": engConnect,
    "traffic incident report": engConnect,
    "emergency response plan": engConnect,
    "hazardous materials": engConnect,
    "building code compliance": engConnect,
    "building permit application": engConnect,
    "demolition permit": engConnect,
    "land development regulations": engConnect,
    "historic preservation": engConnect,
    "building inspection": engConnect,
    "property assessment": engConnect,
    "zoning variance": engConnect,
    "public land management": engConnect,
    "park maintenance": engConnect,
    "recreation program": engConnect,
    "trail maintenance": engConnect,
    "environmental conservation": engConnect,
    "wildlife habitat preservation": engConnect,
    "energy efficiency program": engConnect,
    "water conservation": engConnect,
    "recycling initiative": engConnect,
    "community outreach event": engConnect,
    "volunteer opportunity": engConnect,
    "disaster preparedness": engConnect,
    "emergency shelter": engConnect,
    "emergency evacuation route": engConnect
};

console.log(images, "Images")

const MainContext = React.createContext(null);



// Fisher-Yates shuffle algorithm
const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}



let Filters = <defs>
    <linearGradient id="blue-gradient" x1="50%" y1="-2.48949813e-15%" x2="50%" y2="100%" >
        <stop stopColor="#5757D9" offset="0%" />
        <stop stopColor="#21D9F7" offset="100%" />
    </linearGradient>
    <g>
        <filter id="sepia" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feColorMatrix type="matrix" values="1 0 0 0 0
            1 0 0 0 0
            1 0 0 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix" />
            <feComponentTransfer in="colormatrix" result="componentTransfer">
                <feFuncR type="table" tableValues="0.26 0.95" />
                <feFuncG type="table" tableValues="0.19 0.78" />
                <feFuncB type="table" tableValues="0.11 0.59" />
                <feFuncA type="table" tableValues="0 1" />
            </feComponentTransfer>
            <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"></feBlend>
        </filter>
    </g>
    <g>
        <filter id="blur">
            <feGaussianBlur
                stdDeviation="5" />
            <feColorMatrix
                type="saturate" values=".5" />
        </filter>
    </g>
    <g>
        <filter id="duotone" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feColorMatrix type="matrix"
                values=".33 .33 .33 0 0
                .33 .33 .33 0 0
                .33 .33 .33 0 0
                0 0 0 1 0"
                in="SourceGraphic" result="colormatrix"></feColorMatrix>

            <feComponentTransfer in="colormatrix" result="componentTransfer">
                <feFuncR type="table" tableValues=".996078431 .984313725"></feFuncR>
                <feFuncG type="table" tableValues=".125490196 .941176471"></feFuncG>
                <feFuncB type="table" tableValues=".552941176 .478431373"></feFuncB>
            </feComponentTransfer>

            <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="gamma" exponent="1" amplitude="1" offset="0"></feFuncR>
                <feFuncG type="gamma" exponent="1" amplitude="1" offset="0"></feFuncG>
                <feFuncB type="gamma" exponent="1" amplitude="1" offset="0"></feFuncB>
            </feComponentTransfer>

            <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"></feBlend>
        </filter>
    </g>
    <g>
        <filter id="duotone-green" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feColorMatrix type="matrix"
                values="0 1 0 0 0
              0 1 0 0 0
              0 1 0 0 0
              0 0 0 1 0"
                in="SourceGraphic" result="colormatrix">

            </feColorMatrix>
            <feComponentTransfer in="colormatrix" result="componentTransfer">
                <feFuncR type="table" tableValues="0.03 1"></feFuncR>
                <feFuncG type="table" tableValues="0.57 1"></feFuncG>
                <feFuncB type="table" tableValues="0.49 1"></feFuncB>
                <feFuncA type="table" tableValues="0 1"></feFuncA>
            </feComponentTransfer>
            <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"></feBlend>
        </filter>
    </g>
</defs>



const Shapeset1 = [
    <circle cx={250} cy={250} r={200} style={{ stroke: "rgba(250, 162, 0, 0.5)" }} />,
    <polygon
        className="Star"
        points="250,50 315,200 450,220 355,330 400,470 250,390 100,470 145,330 50,220 185,200"
        style={{ stroke: "rgba(250, 162, 0, 0.5)" }}
    />,
    <path
        className="Heart"
        d="M380,150c-35-50-120-90-120-90s-85,40-120,90c-50,50-50,120-50,120s70,40,170,120c100-80,170-120,170-120s0-70-50-120z"
        style={{ stroke: "rgba(250, 162, 0, 0.5)" }}
    />,
    <polygon
        className="Hexagon"
        points="175,50 425,50 500,250 425,450 175,450 100,250"
        style={{ stroke: "rgba(250, 162, 0, 0.5)" }}
    />,
    <path class="shield" d="M404.48,187.94c-.93-.82-2.31-.91-3.34-.23-11.59,7.66-26.63,12.29-43.07,12.29-22.94,0-43.16-9.02-55.08-22.72-1.06-1.22-2.9-1.3-4.06-.18-12.16,11.78-30.87,19.34-51.86,19.34-16.39,0-31.38-4.6-42.95-12.22-.78-.51-1.76-.61-2.62-.23h0c-9.19,4.32-15.1,13.92-15.08,24.49v79.1c.82,57.97,35.78,109.33,87.77,128.95l11.15,4.23c4.69,1.85,9.65,2.84,14.66,2.93,5.14-.02,10.24-.97,15.08-2.82l11.05-4.23c51.91-19.71,86.75-71.12,87.46-129.06v-79.1c.02-8.05-3.41-15.52-9.1-20.54Z" style={{ stroke: "rgba(250, 162, 0, 0.5)" }} />


]





const gldraw = (gl, image) => {
    const vertShaderSource = `
        attribute vec2 position;
        varying vec2 texCoords;
        void main() {
          texCoords = (position + 1.0) / 2.0;
          texCoords.y = 1.0 - texCoords.y;
          gl_Position = vec4(position, 0, 1.0);
        }
      `;
  
    const fragShaderSource = `
        precision highp float;
        varying vec2 texCoords;
        uniform sampler2D textureSampler;
        void main() {
          vec4 color = texture2D(textureSampler, texCoords);
          gl_FragColor = color;
        }
      `;
  
    const vertShader = gl.createShader(gl.VERTEX_SHADER);
    const fragShader = gl.createShader(gl.FRAGMENT_SHADER);
  
    gl.shaderSource(vertShader, vertShaderSource);
    gl.shaderSource(fragShader, fragShaderSource);
  
    gl.compileShader(vertShader);
    gl.compileShader(fragShader);
  
    const program = gl.createProgram();
    gl.attachShader(program, vertShader);
    gl.attachShader(program, fragShader);
  
    gl.linkProgram(program);
  
    gl.useProgram(program);
  
    const vertices = new Float32Array([
      -1, -1,
      -1, 1,
      1, 1,
  
      -1, -1,
      1, 1,
      1, -1,
    ]);
  
    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
  
    const positionLocation = gl.getAttribLocation(program, 'position');
  
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(positionLocation);
  
    const texture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
  
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  
  }
  

    
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};

  
const Txx = [
    "translate(60.966 295.925)",
    [121, 20, "white", 50],
    [38, 100, "white", 57],
    [41, 141, "white", 30, "50"]
]

  
const Calender= ({CountyName, countyOpenDates})=>{
    return [
        [`${CountyName} is`, countyOpenDates, "Date: 01/01/2023", "New Year's Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 01/16/2023", "Martin Luther King Jr. Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 02/20/2023", "Washington's Birthday"],
        [`${CountyName} is`, countyOpenDates, "Date: 05/29/2023", "Memorial Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 07/04/2023", "Independence Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 09/04/2023", "Labor Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 10/09/2023", "Columbus Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 11/10/2023", "Veterans Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 11/23/2023", "Thanksgiving Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 12/25/2023", "Christmas Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 01/01/2024", "New Year's Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 01/15/2024", "Martin Luther King Jr. Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 02/19/2024", "Washington's Birthday"],
        [`${CountyName} is`, countyOpenDates, "Date: 05/27/2024", "Memorial Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 07/04/2024", "Independence Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 09/02/2024", "Labor Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 10/14/2024", "Columbus Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 11/11/2024", "Veterans Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 11/28/2024", "Thanksgiving Day"],
        [`${CountyName} is`, countyOpenDates, "Date: 12/25/2024", "Christmas Day"],
        // Add more holidays here
      ]
    
}

  
const Agenda= ({CountyName, countyOpenDates})=>{
    return [
        [`${CountyName} is`, countyOpenDates, "Date: 01/01/2023", "Agenda"],
        [`${CountyName} is`, countyOpenDates, "Date: 01/16/2023", "Meeting"],
        [`${CountyName} is`, countyOpenDates, "Date: 02/20/2023", "Papa"],
        [`${CountyName} is`, countyOpenDates, "Date: 01/01/2023", "Agenda"],
        [`${CountyName} is`, countyOpenDates, "Date: 01/16/2023", "Meeting"],
        [`${CountyName} is`, countyOpenDates, "Date: 02/20/2023", "Papa"],

        // Add more holidays here
      ]
    
}



const useMavic = (key, initialState) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue === null ? initialState : JSON.parse(storedValue);
    })

    useEffect(() => {
        const listener = (e) => {
            if (e.storageArea === localStorage && e.key === key) {
                setValue(JSON.parse(e.newValue))
            }
        }
        window.addEventListener("storage", listener)
        return () => {
            window.removeEventListener('storage', listener)
        }
    }, [key])

    const setValueInLocalStorage = (newValue) => {
        setValue((currentValue) => {
            const result = typeof newValue === "function" ? newValue(currentValue) : newValue;
            localStorage.setItem(key, JSON.stringify(result))
            return result
        })
    }
    return [value, setValueInLocalStorage]
}

  
export { MainContext, useMavic, images, shuffleArray, Colors, Txx, Filters, Shapeset1, ImageIn,PostIn, Calender, Agenda, PositiveSet, gldraw, getBase64FromUrl, mediaLinks  }