import React, { useState, useEffect, useContext } from 'react';
import './print.css'
import { MainContext } from '../mainContext';


const Print = ()=> {
  const {url } = useContext(MainContext)
  const [isLoading, setIsLoading] = useState(true);

  const handleMessage = (event) => {
    if (event.data.action === 'loaded') {
      setIsLoading(false);
    }
  };

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      <iframe
        id="Records"
        src={`${window.location.origin}/${url}`}
        style={{ display: 'none' }}
        title="Records"
      />
      <section>
      <button onClick={() => printIframe('Records')} style={{background: "pink", color: "red"}}>Download You Password key</button>
        {isLoading ? 
       <p>hahaha</p>
        : 'Loading'}
          
      </section>
    </div>
  );
}

export default Print;